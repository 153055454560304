
import Vue from 'vue';
import Component from 'vue-class-component';
import dayjs from 'dayjs';
// Repository
import { RepositoryFactory } from '../repositories/RepositoryFactory';
const UuidQuestionRepository = RepositoryFactory.get('uuid_question');

@Component
export default class QuestionStart extends Vue {
  // 回答紙
  questionnaire: any = {};
  // 質問紙ID
  questionnaire_id = 1;
  // 本日
  today = dayjs().format('YYYY-MM-DD');
  // 質問を質問群単位で表示するかどうか
  is_every_question_displayed_by_group = false;

  // 1ページ目（questionnaire_id/question_groupのindex/questionのindex）
  next(): void {
    this.$store.commit('answer/setAnswers', {});
    if (this.is_every_question_displayed_by_group) {
      this.$router.push('/preview/questionnaire-by-group/' + this.$route.params.uuid + '/1');
    } else {
      this.$router.push('/preview/questionnaire/' + this.$route.params.uuid + '/1/1');
    }
  }

  // プレビュー画面を閉じる
  close(): void {
    window.close();
  }

  // Loadingの表示，非表示の制御
  toggleLoading(params: { is_loading: boolean; time: number }): void {
    this.$emit('toggleLoading', params);
  }

  // 質問紙名を読み込んだ後に画面に表示。読み込みの前はデフォルト値'0000-00-00'を表示
  get questionnaireName(): string {
    if (this.questionnaire && this.questionnaire.name) {
      return this.questionnaire.name;
    } else {
      return '0000-00-00';
    }
  }

  get hasQuestionnaire(): boolean {
    return this.questionnaire != null;
  }

  created(): void {
    // .envのVUE_APP_DISPLAY_QUESTIONS_BY_GROUP === '1'の場合、質問群単位で質問表示
    this.is_every_question_displayed_by_group = process.env.VUE_APP_DISPLAY_QUESTIONS_BY_GROUP === '1';
  }

  //
  async mounted(): Promise<any> {
    // ローディング
    this.toggleLoading({ is_loading: true, time: 0 });

    // 質問リスト取得
    this.questionnaire = (await UuidQuestionRepository.questionnaire(this.$route.params.uuid)).data.list;

    if (this.questionnaire == null) {
      this.toggleLoading({ is_loading: false, time: 0.5 });
      return;
    }

    // 質問リストをstoreに保存
    await this.$store.commit('questionnaire/setQuestionnaire', this.questionnaire);

    // ブラウザバックを無効化
    addEventListener('popstate', () => {
      this.$router.push('/preview/' + this.$route.params.uuid + '/start');
    });

    // スクロールリセット
    window.scrollTo(0, 0);

    // ローディングOFF
    this.toggleLoading({ is_loading: false, time: 0.5 });
  }
}
